.hamburger-menu {
    display: none;
}
@media (max-width: 767.98px) {
    .navbar-collapse {
        top: 58px;
    }
    .hamburger-menu {
        display: inline-block;
        border: 1px solid #172b4d;
        border-radius: 3px;
        padding: 5px 10px;
        background-color: transparent;
        color: #172b4d;
        cursor: pointer;
    }
}