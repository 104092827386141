.customTabs .react-tabs__tab-list .react-tabs__tab {
  text-align: left;
  /* font: normal normal 600; */
  letter-spacing: 0px;
  color: #464343;
  opacity: 1;
  background: none;
  margin-left: 15px;
  font-family: Open Sans, sans-serif;
}

.customTabs .react-tabs__tab-list {
  border-bottom: none;
  border-color: transparent;
  border-radius: unset;
}

.customTabs .react-tabs__tab {
  display: inline-block;
  border: none;
  border-bottom: none;
  border-color: none;
  position: relative;
  list-style: none;
  padding: 15px;
  cursor: pointer;
}
.react-bootstrap-table table {
  table-layout: auto;
}

.customTabs .react-tabs__tab--selected {
  border-bottom: 5px solid #09aeec;
  box-shadow: none;
  outline: none;
  background: transparent;
}

.customTabs .react-tabs__tab-panel {
  margin: -8px 15px 0;
}

.sc-dlnjPT.ctJDeu.rdt_TableHeadRow {
  background: #d8deea 0% 0% no-repeat padding-box;
}

.sc-crzoUp.cpjOdh.rdt_TableCol_Sortable {
  text-align: left;
  font: normal normal normal 14px/20px Lorin;
  letter-spacing: 0px;
  color: #1d1d1d;
  opacity: 1;
}
.sc-jrsJCI.cUPwnG.rdt_TableRow,
.sc-jrsJCI.gpQyUX.rdt_TableRow {
  border: none;
}

.gpQyUX {
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

/* ActiveOrder Tracking */

.breadcrumb .breadcrumb-item {
  text-decoration: underline;
  font: normal normal normal 11px/15px Lorin;
  color: #666666;
}
.breadcrumb {
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8898aa;
  content: ">";
}

.trackingComponent .orderId {
  display: inline-flex;
}

.trackingComponent .orderId h3 {
  font: normal normal normal 17px/16px Lorin;
  color: #0b7cd8;
  margin-right: 5px;
}

.trackingComponent .orderId p {
  font: normal normal normal 12px/16px Lorin;
  color: #6a6a6a;
}

.trackingComponent .orderId {
  display: inline-flex;
}

.allCards {
  margin-top: 20px;
}
.allCards .card-header {
  text-align: left;
  font: normal normal normal 20px/26px Lorin;
  letter-spacing: 0px;
  color: #4e4e4e;
  opacity: 1;
}
.allCards .secondCard p {
  font: normal normal normal 14px/18px Lorin;
  letter-spacing: 0px;
  color: #acacac;
  opacity: 1;
}

.trackButtons {
  margin: 35px 0px;
}
.trackButtons .btn-outline-primary {
  color: #12abea;
  border-color: #12abea;
  border-radius: 20px;
  width: 145px;
}

.trackButtons .btn-outline-primary:hover {
  color: #12abea;
  border-color: #12abea;
  background-color: #ffff;
}

.trackButtons .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #ffff;
  border-color: #12abea;
  background-color: #12abea;
}

.trackButtons .btn-primary {
  color: #ffff;
  border-color: #12abea;
  border-radius: 20px;
  background-color: #12abea;
  width: 145px;
}

.trackButton .btn-primary {
  color: #ffff;
  border-color: #12abea;
  border-radius: 20px;
  background-color: #12abea;
  width: 145px;
}

.trackButtons .btn-primary:not(:disabled):not(.disabled):active {
  color: #ffff;
  border-color: #12abea;
  background-color: #12abea;
}

/* .allCards .col-md-2{
    padding: 0;
} */

.allCards .AddOn {
  margin-top: 50px;
}

.stepper
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(-n + 5)
  div:nth-child(1) {
  background: #ebf1fe 0% 0% no-repeat padding-box !important;
  width: 39px !important;
  height: 39px !important;
  padding: 5px !important;
}

.stepper
  div:nth-child(1)
  div:nth-child(1)
  div:nth-child(-n + 5)
  div:nth-child(1)
  img {
  padding: 0px 2px 10px 2px;
}

.allCards .secondCard img {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  padding: 0 15px;
  border: 1px solid #cccccc;
}

.allCards .card-body p {
  margin: 0;
}

.star-ratings {
  bottom: 3px;
  left: 10px;
}
.allCards .main-service-vehicle-header {
  background: #c2dbf0 0% 0% no-repeat padding-box;
}
.allCards .main-service-vehicle-header h1 {
  text-align: left;
  font: normal normal bold 18px/22px Lorin;
  letter-spacing: 0px;
  color: #4e4e4e;
  opacity: 1;
}
