.serviceCheckSa {
  display: flex;
  align-items: center;
}

.serviceNumberField {
  display: flex;
  height: 130px;
  align-items: center;
}

.serviceCheckField {
  display: flex;
  height: 105px;
  align-items: center;
}
