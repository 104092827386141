@media only screen and (min-width: 1200px) {
  .dashboard_cards {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .visibilitySmallDevice {
    display: none;
  }
}
@media only screen and (max-width: 599px) {
  .visibilityMediumDevice {
    display: none;
  }
}
