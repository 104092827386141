.tableOrder {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tableOrder .tableItem {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.tableOrder tr:nth-child(even) {
  background-color: #dddddd;
}
