@media only screen and (max-width: 599px) {
  .buttonCancelSubmitCol {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 599px) {
  .buttonConvert {
    padding: 0.625rem 0rem;
  }
}
