.massage {
  color: red;
}

@media only screen and (max-width: 599px) {
  .addOnButton {
    margin-top: 13px;
  }
  .buttonPadding {
    padding: 0.625rem 1.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .buttonPadding {
    padding: 0.625rem 0rem;
  }
}
@media only screen and (min-width: 1025px) {
  .visibilitySmallDeviceSp {
    display: none;
  }
}
@media only screen and (max-width: 1025px) {
  .visibilityMediumDeviceSp {
    display: none;
  }
}
