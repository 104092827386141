#snackbar {
  visibility: visible;
  min-width: 250px;
  margin-left: -125px;
  background-color: #142240;
  color: #fff;
  text-align: center;
  padding: 10px 15px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  font-size: 17px;
  border-radius: 3px;
  font-family: "Jaldi", sans-serif;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4s;
  animation: fadein 0.5s, fadeout 0.5s 4s;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 1000;
}

@-webkit-keyframes fadein {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 10px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 10px;
    opacity: 0;
  }
}
