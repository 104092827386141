.loading {
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100%;
}
@media only screen and (max-width: 320px) {
  .loading {
    left: 17%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }
}
@media only screen and (max-width: 376px) {
  .loading {
    left: 20%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }
}
@media only screen and (max-width: 426px) {
  .loading {
    left: 25%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }
}
